<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Assume that the enthalpy of crystallization (lattice energy) for KCl is
        <number-value :value="latt" unit="\text{kJ/mol,}" />
        and the enthalpies of hydration for potassium and chloride are
        <number-value :value="K" unit="\text{kJ/mol}" />
        and
        <number-value :value="Cl" unit="\text{kJ/mol,}" />
        respectively. From these values, estimate both the enthalpy of hydration and the enthalpy of
        solution for KCl.
      </p>

      <calculation-input
        v-model="inputs.Hhydration"
        class="mb-3"
        prepend-text="$\Delta\text{H}_\text{hydration}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.Hsolution"
        prepend-text="$\Delta\text{H}_\text{solution}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question408',
  components: {
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        Hhydration: null,
        Hsolution: null,
      },
    };
  },
  computed: {
    latt() {
      return this.taskState.getValueBySymbol('latt').content;
    },
    K() {
      return this.taskState.getValueBySymbol('K').content;
    },
    Cl() {
      return this.taskState.getValueBySymbol('Cl').content;
    },
  },
};
</script>
